import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectToolbarTheme } from "app/store/fuse/settingsSlice";
import { Button } from "@mui/material";
import { Search } from "app/shared-components/search";
import { SignInDialog } from "app/shared-components/auth/SignInDialog";
import { SignUpDialog } from "app/shared-components/auth/SignUpDialog";

function ToolbarLayout1(props) {
  const toolbarTheme = useSelector(selectToolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        elevation={1}
        id="fuse-toolbar"
        className={clsx(
          "flex relative z-20 bg-main-bg py-4  ",
          props.className
        )}
        sx={{
          backgroundColor: (theme) => toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64 w-full bg-main-bg">
          <div className="flex items-center justify-between gap-8 w-full px-24">
            <img
              className="logo-icon py-2 mt-2 h-36 ml-4"
              src="assets/images/logo/logo-text.png"
              alt="logo"
            />
            
            <div className="flex gap-8 justify-end">
              <SignUpDialog />
              <SignInDialog />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);

import { Container, Box, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

const MotionDiv = motion(Box);

// Add this CSS at the top level of your component
const styles = `

  #terms-content h1{
    margin-top: 48px !important;
    margin-bottom: 24px !important;
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 32px !important;
    letter-spacing: -0.01em !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1.2 !important;
  }
  #terms-content h2 {
    margin-top: 48px !important;
    margin-bottom: 24px !important;
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 24px !important;
    letter-spacing: -0.01em !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1.2 !important;
  }

  #terms-content h3 {
    margin-top: 32px !important;
    margin-bottom: 16px !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 18px !important;
    letter-spacing: -0.01em !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1.3 !important;
  }

  #terms-content p {
    margin-bottom: 20px !important;
    color: #000 !important;
    line-height: 1.8 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  #terms-content ul,
  #terms-content ol {
    margin: 0 0 24px 0 !important;
    padding-left: 20px !important;
    color: #000 !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  #terms-content li {
    margin-bottom: 12px !important;
    padding-left: 8px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.7 !important;
  }

  #terms-content a {
    color: #1976d2 !important;
    text-decoration: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

  #terms-content a:hover {
    text-decoration: underline !important;
    color: #1565c0 !important;
  }

  @media (max-width: 600px) {
    #terms-content h2 { font-size: 28px !important; }
    #terms-content h3 { font-size: 18px !important; }
    #terms-content p,
    #terms-content li { font-size: 15px !important; }
  }
`;

function ContentPage({ content: pageContent, maxWidth = "md" }) {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  const renderContent = (item, index) => {
    switch (item.type) {
      case 'h1':
        return <h1 key={index}>{item.content}</h1>;
      case 'h2':
        return <h2 key={index}>{item.content}</h2>;
      case 'h3':
        return <h3 key={index}>{item.content}</h3>;
      case 'p':
        return <p key={index}>{item.content}</p>;
      case 'ul':
        return (
          <ul key={index}>
            {item.content.map((listItem, i) => (
              <li key={`${index}-${i}`}>{listItem}</li>
            ))}
          </ul>
        );
      case 'ol':
        return (
          <ol key={index}>
            {item.content.map((listItem, i) => (
              <li key={`${index}-${i}`}>{listItem}</li>
            ))}
          </ol>
        );
      case 'link':
        return (
          <a 
            key={index} 
            href={item.url}
            target={item.external ? "_blank" : "_self"}
            rel={item.external ? "noopener noreferrer" : ""}
          >
            {item.content}
          </a>
        );
      default:
        return <p key={index}>{item.content || item}</p>;
    }
  };

  return (
    <Container maxWidth={maxWidth}>
      <style>{styles}</style>
      <MotionDiv
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Paper 
          elevation={0}
          sx={{
            borderRadius: 2,
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            px: { xs: 3, md: 6 },
            py: { xs: 4, md: 6 },
            mb: 4
          }}
        >
          <div id="terms-content">
            {pageContent.map((item, index) => renderContent(item, index))}
          </div>
        </Paper>
      </MotionDiv>
    </Container>
  );
}

export default ContentPage; 
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function PageHeader({ title, link, onLinkClick }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row justify-between py-4">
      <Typography
        variant="h5"
        className="text-[20px] font-extrabold text-main-text-color"
      >
        {title}
      </Typography>
      {(link || onLinkClick) && (
        <Button
          onClick={() => {
            if (link) {
              navigate(link );
            }
            onLinkClick?.();
          }}
          variant="text"
          className="text-main-text-color hover:underline"
        >
          Show all
        </Button>
      )}
    </div>
  );
}

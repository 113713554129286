const TermsOfUseContent = () => {
    return [
    {
    type: 'h1',
    content: 'Terms of Use for PodyC'
    },
    {
    type: 'p',
    content: 'Effective Date: 3rd January 2025'
    },
    {
    type: 'p',
    content: 'Welcome to PodyC, an AI podcast creation and listening app provided by Imversion Technologies ("Company," "we," "us," or "our"). These Terms of Use ("Terms") govern your use of our mobile application ("App") and the content generation services, including AI-generated podcasts and voices ("Services"). By using the App, you agree to comply with these Terms. If you do not agree, please do not use the App.'
    },
    {
    type: 'h2',
    content: '1. Use of the App and Services'
    },
    {
    type: 'p',
    content: 'You must be at least 13 years old (or the minimum age in your jurisdiction) to use the App and Services. You agree to use the App and Services only for lawful purposes and in accordance with these Terms.'
    },
    {
    type: 'ul',
    content: [
    'You will not use the App or Services in any way that violates applicable laws or regulations.',
    'You will not attempt to gain unauthorized access to the App, its systems, or AI generation features.',
    'You will not use the App or Services to generate or distribute harmful, illegal, or inappropriate content.'
    ]
    },
    {
    type: 'h2',
    content: '2. User Account'
    },
    {
    type: 'p',
    content: 'To access certain features, including content generation, you may need to create an account. You agree to provide accurate information and maintain the confidentiality of your account credentials.'
    },
    {
    type: 'ul',
    content: [
    'You are responsible for all activities under your account.',
    'You must notify us immediately if you suspect unauthorized access to your account.'
    ]
    },
    {
    type: 'h2',
    content: '3. AI-Generated Content and Voices'
    },
    {
    type: 'p',
    content: 'The App allows users to generate podcasts and other content using AI technology, including AI voices. By using these features, you agree to the following:'
    },
    {
    type: 'ul',
    content: [
    'You are solely responsible for the content you generate using the App and Services.',
    'You must not use the AI-generated content or voices for unlawful, harmful, or fraudulent purposes.',
    'You acknowledge that the generated content is created by AI and may not always be accurate or free of errors.'
    ]
    },
    {
    type: 'h2',
    content: '4. Content Ownership and License'
    },
    {
    type: 'p',
    content: 'All content you create using the App remains your intellectual property. By using the App, you grant us a non-exclusive, worldwide, royalty-free license to use your content for providing the service.'
    },
    {
    type: 'ul',
    content: [
    'You represent that you have the necessary rights to share your content through the App.',
    'You must not create or share content that infringes on the intellectual property rights of others.'
    ]
    },
    {
    type: 'h2',
    content: '5. Prohibited Conduct'
    },
    {
    type: 'p',
    content: 'You agree not to engage in the following activities:'
    },
    {
    type: 'ul',
    content: [
    'Reverse engineering or decompiling the App or its AI models.',
    'Introducing viruses, malware, or other harmful software.',
    'Using the App to harass, abuse, or harm others.'
    ]
    },
    {
    type: 'h2',
    content: '6. Termination'
    },
    {
    type: 'p',
    content: 'We reserve the right to suspend or terminate your access to the App and Services at any time, for any reason, including violations of these Terms.'
    },
    {
    type: 'h2',
    content: '7. Limitation of Liability'
    },
    {
    type: 'p',
    content: 'To the fullest extent permitted by law, Imversion Technologies is not liable for any indirect, incidental, or consequential damages arising from your use of the App or Services.'
    },
    {
    type: 'h2',
    content: '8. Modifications to the App, Services, and Terms'
    },
    {
    type: 'p',
    content: 'We may update the App, Services, or these Terms at any time. Continued use of the App constitutes acceptance of the updated Terms.'
    },
    {
    type: 'h2',
    content: '9. Governing Law'
    },
    {
    type: 'p',
    content: 'These Terms are governed by the laws of [Your Jurisdiction], without regard to conflict of law principles.'
    },
    {
    type: 'h2',
    content: '10. Contact Us'
    },
    {
    type: 'p',
    content: 'If you have questions about these Terms, please contact us at:'
    },
    {
    type: 'ul',
    content: [
    'Imversion Technologies',
    'Email: support@imversion.com'
    ]
    }
    ];
};

export default TermsOfUseContent;
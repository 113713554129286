import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getFileResult = createAsyncThunk(
  "fileResult/getFileResult",
  async (id) => {
    const response = await axios
      .get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/file-results-models?filters[knowledgebase]=${id}&sort[createdAt]=desc&pagination[page]=1&pagination[pageSize]=10`
      )
      .catch((error) => {
        console.log(error);
      });

    return response.data;
  }
);

export const getAllFeaturedPodcasts = createAsyncThunk(
  "fileResult/getAllFeaturedPodcasts",
  async ({ page, limit }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/featured`,
        {
          params: {
            skip: page * limit,
            limit: limit,
          },
        }
      );
      
      const results = response?.data?.data?.map((d) => ({
        id: d.id,
        attributes: { ...d },
      }));

      return {
        data: results,
        meta: response.data.meta,
        hasMore: results.length === limit,
        page,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const getFeaturedPodcasts = createAsyncThunk(
  "fileResult/getFeaturedPodcasts",
  async (limit = 10) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/random-public-tracks`,
        {
          params: {
            limit: limit,
          },
        }
      );

      const results = response?.data?.data?.map((d) => ({
        id: d.id,
        attributes: { ...d },
      }));

      return {
        data: results,
        meta: response.data.meta,
      };
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const initialState = {
  data: [],
  meta: {},
  myPodcastsLoading: false,
  selectedFileResult: null,
  isPlaying: false,
  featuredPodcastsData: [],
  featuredPodcastsMeta: {},
  allFeaturedPodcastsData: [],
  allFeaturedPodcastsMeta: {},
  hasMore: true,
  isLoading: false,
  error: null
};

const fileResultSlice = createSlice({
  name: "fileResult",
  initialState,
  reducers: {
    setSelectedFileResult: (state, action) => {
      state.selectedFileResult = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setFeaturedPodcasts: (state, action) => {
      state.featuredPodcastsData = action.payload.data;
      state.featuredPodcastsMeta = action.payload.meta;
    },
    setAllFeaturedPodcasts: (state, action) => {
      state.allFeaturedPodcastsData = action.payload.data;
      state.allFeaturedPodcastsMeta = action.payload.meta;
    },
  },
  extraReducers: {
    [getFileResult.pending]: (state) => {
      state.myPodcastsLoading = true;
    },
    [getFileResult.fulfilled]: (state, action) => ({
      ...state,
      myPodcastsLoading: false,
      data: action.payload.data,
      meta: action.payload.meta,
    }),
    [getFileResult.rejected]: (state) => {
      state.myPodcastsLoading = false;
    },
    [getFeaturedPodcasts.fulfilled]: (state, action) => ({
      ...state,
      featuredPodcastsData: action.payload.data,
      featuredPodcastsMeta: action.payload.meta,
    }),
    [getAllFeaturedPodcasts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAllFeaturedPodcasts.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.page === 0) {
        state.allFeaturedPodcastsData = action.payload.data;
      } else {
        state.allFeaturedPodcastsData = [
          ...state.allFeaturedPodcastsData,
          ...action.payload.data
        ];
      }
      state.allFeaturedPodcastsMeta = action.payload.meta;
      state.hasMore = action.payload.hasMore;
    },
    [getAllFeaturedPodcasts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

// Update selector to include myPodcastsLoading
export const selectFileResult = (state) => ({
  data: state.fileResult.data,
  myPodcastsLoading: state.fileResult.myPodcastsLoading,
});
export const selectFeaturedPodcasts = ({ fileResult }) => fileResult;
export const selectAllFeaturedPodcasts = ({ fileResult }) =>
  fileResult;
export const { setFeaturedPodcasts } = fileResultSlice.actions;
export const { setSelectedFileResult } = fileResultSlice.actions;
export const selectSelectedFileResult = ({ fileResult }) =>
  fileResult.selectedFileResult;

export const selectIsPlaying = ({ fileResult }) => fileResult.isPlaying;
export const { setIsPlaying } = fileResultSlice.actions;

export default fileResultSlice.reducer;

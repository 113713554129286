// src/components/StatsCard.js


import LoyaltyIcon from '@mui/icons-material/Loyalty';
import history from '@history';
import { useSelector } from 'react-redux';
import { selectUsage } from 'app/store/usageSlice';
import { selectUser } from 'app/store/userSlice';
import { useDispatch } from 'react-redux';

const MessageCard = () => {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const items = [

    { icon: <LoyaltyIcon />, title: "We're in beta", description: "Click here to try Podyc for free with a 14-day trial" },

  ];

  return (
    (user && user.data.organization.subscription === "unsubscribed" &&
       <div className="bg-primary p-8 rounded-lg border border-main-bg/40 text-main-text-color hover:scale-105 transition-all duration-300 cursor-pointer"
    onClick={() => {
      history.push("/dashboards/organisation");
    }}>
      {items.map((item, index) => (
        <div key={index} className="flex flex-col px-8">
          <div className="flex items-center text-main-bg">
            <div className="flex items-center">
              {item.icon}
            </div>
            <div className="ml-8">
              <h3 className="font-bold text-main-bg">{item.title}</h3>
              <p className="text-main-bg">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>)
  );
};

export default MessageCard;

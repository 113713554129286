// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import './i18n';  // Import your internationalization setup
import './styles/app-base.css';  // Import your base styles
import './styles/app-components.css';  // Import your component styles
import './styles/app-utilities.css';  // Import your utility styles

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter from react-router-dom
import App from './app/App';  // Import your main App component
import * as serviceWorker from './serviceWorker';  // Import service worker setup
import reportWebVitals from './reportWebVitals';  // Import web vitals report

// Select the container element in your HTML
const container = document.getElementById('root');

// Create root and render your App component inside a BrowserRouter
const root = createRoot(container);
root.render(
    <App />
);

// Report web vitals for performance tracking
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

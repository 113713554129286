const PrivacyPolicyContent = () => {
    return [
      {
        type: 'h1',
        content: 'Privacy Policy for PodyC'
      },
      {
        type: 'p',
        content: 'Effective Date: 03/01/2025\n\nLast Updated: 03/01/2025'
      },
      {
        type: 'p',
        content: 'Welcome to PodyC, an AI podcast creation and listening app provided by Imversion Technologies. Your privacy is important to us, and this Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application available on the Android and iOS platforms.'
      },
      {
        type: 'h2',
        content: '1. Information We Collect'
      },
      {
        type: 'h3',
        content: '1.1. Information You Provide to Us'
      },
      {
        type: 'p',
        content: 'Account Information: When you create an account, we may collect your name, email address, username, and profile picture.\nContent Information: When using the App to create or listen to podcasts, we may collect information about the content you create or interact with, including metadata.'
      },
      {
        type: 'h3',
        content: '1.2. Information We Collect Automatically'
      },
      {
        type: 'p',
        content: 'Device Information: Details about the device you use to access the App, including hardware model, operating system version, unique device identifiers, and mobile network information.\nUsage Data: Information about your interactions with the App, such as the features you use, time spent on the App, and error reports.\nLocation Information: With your permission, we may collect geolocation data to personalize your experience.'
      },
      {
        type: 'h3',
        content: '1.3. Information from Third Parties'
      },
      {
        type: 'p',
        content: 'If you connect your account to third-party services (e.g., social media accounts), we may collect information as permitted by those services.'
      },
      {
        type: 'h2',
        content: '2. How We Use Your Information'
      },
      {
        type: 'p',
        content: 'We use the information we collect for the following purposes:\n\n- To Provide and Improve the App: Deliver personalized content, improve functionality, and maintain App performance.\n- To Communicate with You: Send you updates, notifications, and information about your account or the App.\n- To Ensure Security: Monitor and protect against fraud, unauthorized access, or abuse.\n- For Analytics: Understand user behavior to enhance user experience and App performance.\n- For Legal Compliance: Comply with applicable laws, regulations, and legal processes.'
      },
      {
        type: 'h2',
        content: '3. How We Share Your Information'
      },
      {
        type: 'p',
        content: 'We do not sell your personal information. However, we may share your information in the following circumstances:'
      },
      {
        type: 'ul',
        content: [
          'Service Providers: We may share information with third-party vendors who perform services on our behalf, such as hosting, analytics, or customer support.',
          'Legal Obligations: We may disclose your information to comply with legal obligations or respond to lawful requests by public authorities.',
          'Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the successor organization.'
        ]
      },
      {
        type: 'h2',
        content: '4. Data Retention'
      },
      {
        type: 'p',
        content: 'We retain your information only as long as necessary for the purposes outlined in this Privacy Policy unless a longer retention period is required by law.'
      },
      {
        type: 'h2',
        content: '5. Your Choices'
      },
      {
        type: 'ul',
        content: [
          'Account Settings: You can update your account information or delete your account directly through the App.',
          'Marketing Communications: Opt out of promotional emails by following the unsubscribe instructions in those emails.',
          'Permissions: Adjust your device settings to manage permissions for location, notifications, and other features.'
        ]
      },
      {
        type: 'h2',
        content: '6. Data Security'
      },
      {
        type: 'p',
        content: 'We implement appropriate technical and organizational measures to safeguard your information. However, no method of electronic transmission or storage is 100% secure, and we cannot guarantee absolute security.'
      },
      {
        type: 'h2',
        content: '7. Children\'s Privacy'
      },
      {
        type: 'p',
        content: 'The App is not intended for use by children under the age of 13 (or the minimum age in your jurisdiction). We do not knowingly collect personal information from children. If we become aware that we have collected such information, we will delete it promptly.'
      },
      {
        type: 'h2',
        content: '8. International Users'
      },
      {
        type: 'p',
        content: 'Your information may be transferred to and processed in countries other than your country of residence. By using the App, you consent to such transfers.'
      },
      {
        type: 'h2',
        content: '9. Third-Party Links and Services'
      },
      {
        type: 'p',
        content: 'The App may contain links to third-party websites or services. We are not responsible for the privacy practices of such third parties. Please review their privacy policies to understand how they collect and use your information.'
      },
      {
        type: 'h2',
        content: '10. Changes to This Privacy Policy'
      },
      {
        type: 'p',
        content: 'We may update this Privacy Policy from time to time. Any changes will be posted within the App, and the \"Last Updated\" date at the top of this policy will reflect the effective date of the changes. Your continued use of the App signifies your acceptance of any updated terms.'
      },
      {
        type: 'h2',
        content: '11. Contact Us'
      },
      {
        type: 'p',
        content: 'If you have questions or concerns about this Privacy Policy or our data practices, please contact us at:'
      },
      {
        type: 'ul',
        content: [
          'Imversion Technologies',
          'Email: hello@podyc.com'
        ]
      },
      {
        type: 'p',
        content: 'Thank you for trusting PodyC and Imversion Technologies. We are committed to protecting your privacy.'
      }
    ];
  };
  
  export default PrivacyPolicyContent;
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Avatar, CircularProgress } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PauseIcon from "@mui/icons-material/Pause";
import { useSelector } from "react-redux";
import {
  selectCurrentTrack,
  setCurrentTrack,
  setIsPlaying,
  selectIsPlaying,
} from "app/store/playerSlice";
import { useDispatch } from "react-redux";
import { getRandomPoster } from "src/app/utils/album";
import ReactGA from "react-ga4";

const isLoadingExpired = (createdDate) => {
  const diff = Date.now() - Date.parse(createdDate);
  return diff > 1000 * 60 * 15;
};

export default function AlbumCard({ album, isPreview }) {
  const dispatch = useDispatch();
  const currentTrack = useSelector(selectCurrentTrack);
  const isPlaying = useSelector(selectIsPlaying);
  const isLoadExpired = isLoadingExpired(album?.createdAt);

  const title =
    album?.attributes?.status !== "completed"
      ? "Creating your podcast..."
      : album?.attributes?.title ?? "Untitled";

  const cover = album?.attributes?.feature_image_url ?? getRandomPoster();
  const isLoading = album?.attributes?.status !== "completed";
  const onPlayClick = () => {
    ReactGA.event('song_play', {
      category: 'Music Player',
      action: 'Play Song',
      track: album.title,
    });
    dispatch(setCurrentTrack(album));
    dispatch(setIsPlaying(true));
  };

  const onPauseClick = () => {
    ReactGA.event('song_pause', {
      category: 'Music Player',
      action: 'Pause Song',
      track: album.title,
    });
    dispatch(setIsPlaying(false));
  };

  const calculateTransformOrigin = (event) => {
    const card = event.currentTarget;
    const cardRect = card.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate position relative to center (ranges from -1 to 1)
    const xPosition = (cardRect.left + cardRect.width / 2 - windowWidth / 2) / (windowWidth / 2);
    const yPosition = (cardRect.top + cardRect.height / 2 - windowHeight / 2) / (windowHeight / 2);

    // Refined responsive values
    const scaleAmount = windowWidth < 768 ? 1.03 : 1.08;
    const rotateAmount = windowWidth < 768 ? 3 : 8;
    const translateAmount = windowWidth < 768 ? 5 : 15;

    // Smoother calculations for transform
    const rotateY = -xPosition * rotateAmount;
    const rotateX = yPosition * (rotateAmount / 2);
    const translateX = -xPosition * translateAmount;
    const translateY = -yPosition * (translateAmount / 2);

    // Apply transforms with optimized easing
    card.style.transition = 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)';
    card.style.transform = `
      perspective(1000px)
      scale(${scaleAmount})
      rotateY(${rotateY}deg)
      rotateX(${rotateX}deg)
      translateX(${translateX}px)
      translateY(${translateY}px)
      translateZ(0)
    `;
  };

  const resetTransform = (event) => {
    const card = event.currentTarget;
    // Smooth return to original position
    card.style.transition = 'transform 500ms cubic-bezier(0.13, 0.71, 0.305, 1)';
    card.style.transform = 'perspective(1000px) translateZ(0)';
  };

  return (
    <div className="group/card relative p-[2px]">
      <div className="w-full aspect-[1/1.5]" />

      <article className="absolute top-[2px] left-[2px] right-[2px] flex flex-col rounded-md p-16
        transition-all duration-500 ease-out bg-main-bg
        group-hover/card:z-50
        group-hover/card:shadow-[0_10px_40px_-15px_rgba(0,0,0,0.3)]
        group-hover/card:bg-primary/50
        group-hover/card:border group-hover/card:border-white/30
        before:absolute before:inset-0 before:rounded-md before:opacity-0
        before:bg-[#181818] group-hover/card:before:opacity-100
        before:transition-opacity before:duration-500
        group-hover/card:[transform-style:preserve-3d]
        will-change-transform"
        onMouseEnter={calculateTransformOrigin}
        onMouseLeave={resetTransform}>
        <div className="relative z-10 transition-transform duration-200">
          <img
            src={!cover || cover.length === 0 ? getRandomPoster() : cover}
            height={150}
            width={150}
            loading="lazy"
            className="w-full h-auto aspect-square rounded-md"
          />
          {!isPreview && (
            <div className={`absolute right-16 bottom-16 opacity-0
              group-hover/card:opacity-100 transition-opacity duration-200
              delay-500 md:delay-300`}>
              {(() => {
                if (isLoading) {
                  if (isLoadExpired) {
                    return (
                      <Avatar className="bg-grey-300 w-32 h-32">
                        <ErrorOutlineIcon className="text-red-500" />
                      </Avatar>
                    );
                  }
                  return <CircularProgress size={24} />;
                }

                return (
                  <Avatar className="bg-base-purple text-main-bg hover:scale-110 transition duration-400">
                    {isPlaying && currentTrack.id === album.id ? (
                      <PauseIcon onClick={onPauseClick} />
                    ) : (
                      <PlayArrowIcon onClick={onPlayClick} />
                    )}
                  </Avatar>
                );
              })()}
            </div>
          )}
        </div>

        {/* Title and description container */}
        <div className="mt-16 flex flex-col gap-4 relative z-10">
          <h2 className="text-lg font-bold text-main-text-color overflow-hidden
            [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]
            group-hover/card:text-xl group-hover/card:[-webkit-line-clamp:unset]
            transition-all duration-200">
            {title}
          </h2>
          <p className="text-main-text-color/80 text-md overflow-hidden
            [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]
            group-hover/card:text-lg group-hover/card:[-webkit-line-clamp:unset]
            transition-all duration-200">
            {album?.attributes?.excerpt ?? "No description"}
          </p>
        </div>
      </article>
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";

import jwtService from "../../auth/services/jwtService";
import "./sign-in.css";

import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(4, "Password is too short - must be at least 4 chars."),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
  terms: false,
  phonenumber: "",
};

export function SignInForm() {
  const dispatch = useDispatch();
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, isLoading } = formState;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function onSubmit({ email, password }) {
    setLoading(true);
    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage("Login failed. Please check your email and password.");

      });
  }

  return (
    <div className="w-full">
      {errorMessage && (
        <div className="error-message" style={{ color: 'red', marginBottom: '16px' }}>
          {errorMessage}
        </div>
      )}

      <form
        name="loginForm"
        noValidate
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              size="medium"
              style={{
                backgroundColor: "transparent",
              }}
              placeholder="Enter your email address"
              inputProps={{
                //disableUnderline: true, // Disables the default underline to use modern border
                style: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFBFF",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  paddingTop: "2px", // Updated padding for a better look and feel
                  fontWeight: 400,
                  height: "5rem", // Fix height of text field
                  fontSize: "1.5rem", // Base font size
                },
              }}
              autoFocus
              type="email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-24"
              style={{
                backgroundColor: "transparent",
              }}
              placeholder="Enter your password"
              inputProps={{
                //disableUnderline: true, // Disables the default underline to use modern border
                style: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFBFF",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  paddingTop: "2px", // Updated padding for a better look and feel
                  fontWeight: 400,
                  height: "5rem", // Fix height of text field
                  fontSize: "1.5rem", // Base font size
                },
              }}
              type="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              variant="outlined"
              fullWidth
            />
          )}
        />
        <div className="flex w-full">
          <div className="flex flex-row w-full items-center justify-start space-x-0 ">
            {" "}
            {/* Adjusted this div */}
            <Controller
              name="remember"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    label={
                      <div className="!flex !w-full !place-content-between text-base font-regular tracking-tight text-main-text-color">
                        Remember me
                        <span className=""></span>
                        <Link
                          style={{ color: "#dfdde0" }}
                          className="text-base text-primary font-regular"
                          to="/forgot-password"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    }
                    className="!flex !w-full !place-content-between signin-checkbox text-xs cta-label-style "
                    control={
                      <Checkbox
                        size="small"
                        className="cta-label-style"
                        {...field}
                      />
                    }
                  />
                </FormControl>
              )}
            />
          </div>
        </div>
        {loading && (
          <div className="mt-4 flex align-center justify-center items-center">
            <LoadingSpinner size={24} />
          </div>
        )}
        {!loading && (
          <Button
            variant="contained"
            color="secondary"
            className="press-button shine-button w-full mt-16 rounded-md bg-primary hover:bg-primary text-main-bg"
            aria-label="Login"
            // disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            size="large"
          >
            Sign in
          </Button>
        )}
      </form>
    </div>
  );
}

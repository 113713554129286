import FusePageSimple from "@fuse/core/FusePageSimple";
import { Typography, Box, Grid, Paper } from "@mui/material";
import AlbumCard from "app/shared-components/cards/albumCard";
import { PageHeader } from "app/shared-components/PageHeader";
import { getRandomPoster } from "src/app/utils/album";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState, useCallback } from "react";
import {
  getAllFeaturedPodcasts,
  selectAllFeaturedPodcasts
} from "app/store/fileResultSlice";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';

export function FeaturedPodcastsPage() {
  const dispatch = useDispatch();
  const { allFeaturedPodcastsData, isLoading, hasMore } = useSelector(selectAllFeaturedPodcasts);
  const [page, setPage] = useState(0);
  const ITEMS_PER_PAGE = 10;

  // Initial load
  useEffect(() => {
    dispatch(getAllFeaturedPodcasts({ page: 0, limit: ITEMS_PER_PAGE }));
  }, [dispatch]);

  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      dispatch(getAllFeaturedPodcasts({ 
        page: nextPage, 
        limit: ITEMS_PER_PAGE 
      }));
    }
  }, [dispatch, isLoading, hasMore, page]);

  if (isLoading && allFeaturedPodcastsData.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FusePageSimple
      header={
        <Paper 
          elevation={0}
          sx={{
            backgroundColor: 'transparent',
            color: 'white',
            padding: { xs: 2, sm: 3 },
          }}
        >
          <div className="container">
            <Typography 
              variant="h6" 
              component="h6" 
              sx={{ 
                fontWeight: 600,
              }}
            >
              Featured Podcasts
            </Typography>
          </div>
        </Paper>
      }
      content={
        <div id="scrollableDiv" style={{ height: 'calc(100vh - 64px)', overflow: 'auto', width: '100%' }}>
        
          <InfiniteScroll
            dataLength={allFeaturedPodcastsData?.length || 0}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress />
              </Box>
            }
            endMessage={
              <Box sx={{ textAlign: 'center', p: 2 }}>
                <Typography>No more podcasts to load.</Typography>
              </Box>
            }
            scrollableTarget="scrollableDiv"
            scrollThreshold="200px"
          >
            <Box sx={{ 
              p: { xs: 1, sm: 1 },
              width: '100%',
              maxWidth: '100%'
            }}>
              <Grid 
                container 
                spacing={1}
                sx={{
                  width: '100%',
                  margin: '0 auto',
                  maxWidth: 'xl',
                  '& .MuiGrid-item': {
                    width: '100%',
                    display: 'flex'
                  }
                }}
              >
                {allFeaturedPodcastsData?.map((podcast) => (
                  <Grid 
                    item 
                    xs={12} 
                    sm={6} 
                    md={4} 
                    lg={3} 
                    key={podcast.id}
                    sx={{ width: '100%' }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        '& .album-card': {
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          height: '100%',
                          minHeight: { xs: 300, sm: 320, md: 340 },
                          '& .MuiCardMedia-root': {
                            width: '100%',
                            height: { xs: 200, sm: 220, md: 240 },
                            objectFit: 'cover'
                          },
                          '& .MuiCardContent-root': {
                            flexGrow: 1,
                            padding: { xs: 1.5, sm: 2 }
                          }
                        }
                      }}
                    >
                      <AlbumCard 
                        album={podcast} 
                        className="album-card"
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </InfiniteScroll>
        </div>
      }
    />
  );
}

export const getDeviceType = () => {
	  const userAgent = navigator.userAgent;
  
	  if (/mobile/i.test(userAgent)) {
		return 'Mobile';
	  } else if (/tablet/i.test(userAgent)) {
		return 'Tablet';
	  } else {
		return 'Desktop';
	  }
  };
  export const getPlatformType = () => {
	const userAgent = navigator.userAgent;
	if (/android/i.test(userAgent)) {
		return 'Android';
	  } else if (/iphone|ipod|ipad/i.test(userAgent)) {
		return 'iOS';
	  } else if (/web/i.test(userAgent)) {
		return 'Web';
	  } else {
		return 'Unknown';
	  }
  }
import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/sign-in/SignInConfig";
import { HomePageConfig } from "../main/home/HomePageConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import EmailConfirmationConfig from "../main/email-confirmation/EmailConfirmationConfig";
import dashboardsConfigs from "../main/dashboards/dashboardsConfigs";
import ForgotPasswordConfig from "../main/forgot-password/ForgotPasswordConfig";
import ResetPasswordConfig from "../main/reset-password/ResetPasswordConfig";
import pagesConfigs from "../main/pages/pagesConfigs";
import SubscriptionConfig from "../main/subscription/SubscriptionConfig";
import ChatbotDetailPage from "../main/dashboards/knowledge_base/ChatbotDetailPage";
import ChunksListPage from "../main/dashboards/knowledge_base/ChunksListPage";
import ChatBotIntegration from "../main/dashboards/knowledge_base/ChatBotIntegration";
import ChatBotStylePage from "../main/dashboards/knowledge_base/chatbotStyle/chatbotStylePage";
import GoogleAuthCallback from "../main/google-auth/Google-auth-page";
import TurboWriterPage from "../main/dashboards/turboWriter/turboWriterPage";
import { playlistDetailsPageConfig } from "../main/playlist-details/playlistDetailsConfig";
import { MyPodcastsPageConfig } from "../main/podcasts/my-podcasts/MyPodcastsPageConfig";
import { FeaturedPodcastsPageConfig } from "../main/podcasts/featured-podcasts/FeaturedPodcastsPageConfig";
import { FeaturedPlaylistsPageConfig } from "../main/playlists/FeaturedPlaylistsPageConfig";
import { YourFeaturedPlaylistsPageConfig } from "../main/playlists/YourFeaturedPlaylistsPageConfig";
import PrivacyPolicyPage from "../main/terms/PrivacyPolicyPage";
import { PrivacyPolicyConfig } from "../main/terms/PrivacyPolicyConfig";
import TermsOfUsePage from "../main/terms/TermsOfUsePage";
import {TermsOfUseConfig} from "../main/terms/TermsOfUseConfig";

const routeConfigs = [
  ...dashboardsConfigs,
  ...pagesConfigs,
  SubscriptionConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  EmailConfirmationConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
  HomePageConfig,
  TermsOfUseConfig,
  playlistDetailsPageConfig,
  MyPodcastsPageConfig,
  FeaturedPodcastsPageConfig,
  FeaturedPlaylistsPageConfig,
  YourFeaturedPlaylistsPageConfig,
  PrivacyPolicyConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),

  {
    path: "/dashboards/home",
    element: <Navigate to="dashboards/home" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/query",
    element: <Navigate to="dashboards/query" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/knowledge_base",
    element: <Navigate to="dashboards/knowledge_base" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/kanban",
    element: <Navigate to="dashboards/kanban" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/upload",
    element: <Navigate to="dashboards/upload" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/knowledge_base/items_detail_page",
    element: <ChatbotDetailPage />,
  },
  {
    path: "/dashboards/knowledge_base/items_detail_page/:itemId",
    element: <ChatbotDetailPage />,
  },
  {
    path: "/dashboards/knowledge_base/items_detail_page/document/:documentId",
    element: <ChunksListPage />,
  },
  {
    path: "/dashboards/knowledge_base/turbo-writer/:itemId",
    element: <TurboWriterPage />,
  },
  {
    path: "/dashboards/knowledge_base/chat_bot",
    element: <ChatBotIntegration />,
  },
  {
    path: "/dashboards/knowledge_base/chat_bot_style",
    element: <ChatBotStylePage />,
  },
  {
    path: "/dashboards/organisation",
    element: <Navigate to="dashboards/organisation" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "/dashboards/turbo-writer",
    element: <TurboWriterPage />,
  },
  {
    path: "/auth/callback/google",
    element: <GoogleAuthCallback />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "*",
    element: <Navigate to="pages/error/404" />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUsePage />,
  },
];

export default routes;

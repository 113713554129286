import FuseDialog from "@fuse/core/FuseDialog";
import { styled } from "@mui/material/styles";
import FuseMessage from "@fuse/core/FuseMessage";
import FuseSuspense from "@fuse/core/FuseSuspense";
import AppContext from "app/AppContext";
import { memo, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { selectFuseCurrentLayoutConfig } from "app/store/fuse/settingsSlice";
import LeftSideLayout1 from "./components/LeftSideLayout1";
import NavbarWrapperLayout1 from "./components/NavbarWrapperLayout1";
import ToolbarLayout1 from "./components/ToolbarLayout1";
import AudioPlayerUI from "app/shared-components/audio/AudioPlayer";
import { PlayerViewDrawer } from "app/shared-components/player-view-drawer";
import { selectCurrentTrack } from "app/store/playerSlice";
import { selectUser } from "app/store/userSlice";
import { useEffect } from "react";

const Root = styled("div")(({ theme, config }) => ({

  ...(config.mode === "boxed" && {
    clipPath: "inset(0)",
    maxWidth: `${config.containerWidth}px`,
    margin: "0 auto",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  }),
  ...(config.mode === "container" && {
    "& .container": {
      maxWidth: `${config.containerWidth}px`,
      width: "100%",
      margin: "0 auto",
    },
  }),
}));

function Layout1(props) {
  const user = useSelector(selectUser);
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const currentTrack = useSelector(selectCurrentTrack);
  const location = useLocation();

   useEffect(() => {
    // Log a pageview event to GA4 on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname });
  }, [location]);

  useEffect(() => {
    console.log(user, "user");
  }, [user]);

  return (
    <Root
      id="fuse-layout"
      config={config}
      className="w-full flex flex-col h-screen bg-main-bg"
    >
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex min-w-0 flex-1 overflow-y-auto">
        {config.navbar.display && config.navbar.position === "left" && (
          <NavbarWrapperLayout1 />
        )}

        <main
          id="fuse-main"
          className="flex flex-col flex-auto min-h-full min-w-0 relative z-10 bg-main-bg "
        >
          {config.toolbar.display && (
            <ToolbarLayout1
              className={config.toolbar.style === "fixed" && "sticky top-0"}
            />
          )}

          <div className="flex flex-col flex-auto min-h-0 relative z-10 bg-main-bg">
            <FuseDialog />

            <FuseSuspense>{useRoutes(routes)}</FuseSuspense>

            {props.children}
          </div>
        </main>

        {config.navbar.display && config.navbar.position === "right" && (
          <NavbarWrapperLayout1 />
        )}
      </div>
      <div>
        {currentTrack !== null && user?.data?.organization?.id != null && (
           <div className="z-99">
            <AudioPlayerUI />
          </div>
        )}
        <FuseMessage />
        {currentTrack && <PlayerViewDrawer />}
      </div>
    </Root>
  );
}

export default memo(Layout1);
